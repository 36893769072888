var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Search ")]),_c('CCardBody',[_c('CInput',{attrs:{"placeholder":"Customer ID or Subscription ID or Email or Setup Code or https://<domain>"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.send()}}},[(_vm.is_loading)?_c('CSpinner',{attrs:{"color":"secondary","size":"sm"}}):_vm._e(),_vm._v(" Search ")],1)],1)],1),_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" List of accounts ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"itemsPerPage":15,"border":"","items":_vm.normalizedNetworkItems,"fields":_vm.columns},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_c('h2',[_vm._v("No items")])])]},proxy:true},{key:"partition_key",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_c('router-link',{attrs:{"to":{ path: '/profile', query: { network_id: String(item['partition_key']) }}}},[_vm._v(_vm._s(String(item['partition_key'])))]),_c('span',{staticClass:"copy-text-btn",staticStyle:{"margin-left":"5px"},on:{"click":function($event){_vm.copyToClipboard(String(item['partition_key']))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1)]}},{key:"domains",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[(item.domains)?_vm._l((item.domains),function(domain){return _c('div',{key:domain.url},[_c('a',{attrs:{"href":'//'+domain.url,"target":"_blank"}},[_vm._v(_vm._s(domain.url))]),_vm._v(" "),(!domain.enabled)?_c('span',{staticClass:"domain-not-enabled-label"},[_vm._v("(not enabled)")]):_vm._e(),_vm._v(" "),(item.last_used_at !== 'never')?_c('a',{attrs:{"href":domain.log_url,"target":"_blank"}},[_vm._v("log")]):_vm._e()])}):[_vm._v("-")]],2)]}},{key:"dates",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_c('strong',[_vm._v("Created:")]),_vm._v(" "+_vm._s(item.created_at)),_c('br'),_c('strong',[_vm._v("Started:")]),_vm._v(" "+_vm._s(item.sub_started_at)),_c('br'),_c('strong',[_vm._v("Last Paid:")]),_vm._v(" "+_vm._s(item.last_paid_at)),_c('br'),_c('strong',[_vm._v("Last Used:")]),_vm._v(" "+_vm._s(item.last_used_at)+" ")])]}},{key:"subscription",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[(item.sub_id !== '-')?_c('div',[_c('a',{attrs:{"target":"_blank","href":item.sub_url}},[_vm._v(_vm._s(item.sub_id))]),_c('span',{staticClass:"copy-text-btn",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.copyToClipboard(item.sub_url)}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)]):_vm._e(),(item.sub_plan !== '-')?_c('div',[_vm._v(_vm._s(item.sub_plan))]):_vm._e(),(item.sub_gateway !== '-')?_c('div',[_vm._v(_vm._s(item.sub_gateway))]):_vm._e()])]}},{key:"copy",fn:function(ref){
var item = ref.item;
return [_c('td',[(item['card_update_link'] !== '')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" Payment Update Link "),_c('span',{staticClass:"copy-text-btn",on:{"click":function($event){_vm.copyToClipboard(String(item['card_update_link']))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)]):_vm._e(),(item['setup_code'] !== '-')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" Setup Code "),_c('span',{staticClass:"copy-text-btn",on:{"click":function($event){_vm.copyToClipboard(String(item['setup_code']))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)]):_vm._e(),(item['partition_key'] !== '-')?_c('div',{staticClass:"text-nowrap"},[_vm._v(" Login Info "),_c('span',{staticClass:"copy-text-btn",on:{"click":function($event){_vm.copyToClipboard(_vm.formatLoginInfoToCopy(item['partition_key'], item['admin_key']))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)]):_vm._e()])]}},{key:"invoices",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ path: '/invoices', query: { network_id: item.partition_key }},"target":"_blank"}},[_vm._v("Open")])],1)]}},{key:"sub_status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.sub_status === '-')?_c('span',[_vm._v(_vm._s(item.sub_status))]):_c('CBadge',{attrs:{"color":_vm.getColorByStatus(item.sub_status)}},[_vm._v(" "+_vm._s(item.sub_status)),(item.sub_status === 'active' && item.sub_cancel_at)?[_vm._v(", payments stopped")]:_vm._e()],2)],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"danger","block":"","size":"sm"},on:{"click":function($event){return _vm.startCancellationProcess('cancel', item.partition_key)}}},[_vm._v(" Cancel ")]),_c('CButton',{attrs:{"color":"danger","block":"","size":"sm"},on:{"click":function($event){return _vm.startCancellationProcess('cancel', item.partition_key, true)}}},[_vm._v(" Instant Cancel ")])],1)]}}])})],1)],1)],1)],1),_c('CModal',{attrs:{"title":"Choose a reason","color":"info","show":_vm.isModalActive},on:{"update:show":[function($event){_vm.isModalActive=$event},function($event){return _vm.resetCancellationData()}]},scopedSlots:_vm._u([{key:"footer-wrapper",fn:function(){return [_c('footer',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelSubscription()}}},[_vm._v(" OK ")])]})],2)]},proxy:true}],null,true)},[_vm._l((_vm.cancellationReasons),function(option,optionIndex){return _c('CInputRadio',{key:optionIndex,attrs:{"name":"cancellation_reason","label":option,"value":option,"checked":_vm.selectedReason === option},on:{"update:checked":function($event){_vm.selectedReason = option}}})}),(_vm.isSelectedOtherReason)?_c('CInput',{staticStyle:{"margin-top":"5px"},model:{value:(_vm.otherReasonMessage),callback:function ($$v) {_vm.otherReasonMessage=$$v},expression:"otherReasonMessage"}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }