<template>
  <div>
    <CRow>
      <CCol lg="12">

        <CCard>
          <CCardHeader>
            Search
          </CCardHeader>
          <CCardBody>
            <CInput
                v-model="query"
                @keyup.enter="send"
                placeholder="Customer ID or Subscription ID or Email or Setup Code or https://<domain>"/>
            <CButton color="primary" @click.prevent="send()">
              <CSpinner v-if="is_loading" color="secondary" size="sm"/>
              Search
            </CButton>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> List of accounts
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                :itemsPerPage="15"
                border
                :items="normalizedNetworkItems"
                :fields="columns">

              <template #no-items-view>
                <div class="text-center my-5">
                  <h2>No items</h2>
                </div>
              </template>

              <template #partition_key="{item}">
                <td class="text-nowrap">
                  <router-link :to="{ path: '/profile', query: { network_id: String(item['partition_key']) }}">{{String(item['partition_key'])}}</router-link>
                  <span class="copy-text-btn" style="margin-left: 5px;" @click="copyToClipboard(String(item['partition_key']))"><CIcon name="cil-copy"/></span>
                </td>
              </template>

              <template #domains="{item}">
                <td class="text-nowrap">
                  <template v-if="item.domains">
                    <div v-for="domain of item.domains" :key="domain.url">
                      <a :href="'//'+domain.url" target="_blank">{{domain.url}}</a> <span class="domain-not-enabled-label" v-if="!domain.enabled">(not enabled)</span> <a v-if="item.last_used_at !== 'never'" :href="domain.log_url" target="_blank">log</a>
                    </div>
                  </template>
                  <template v-else>-</template>
                </td>
              </template>

              <template #dates="{item}">
                <td class="text-nowrap">
                  <strong>Created:</strong>&nbsp;{{item.created_at}}<br>
                  <strong>Started:</strong>&nbsp;{{item.sub_started_at}}<br>
                  <strong>Last Paid:</strong>&nbsp;{{item.last_paid_at}}<br>
                  <strong>Last Used:</strong>&nbsp;{{item.last_used_at}}
                </td>
              </template>

              <template #subscription="{item}">
                <td class="text-nowrap">
                  <div v-if="item.sub_id !== '-'"><a target="_blank" :href="item.sub_url">{{item.sub_id}}</a>
                    <span class="copy-text-btn"
                          style="margin-left: 5px;"
                          @click="copyToClipboard(item.sub_url)"><CIcon name="cil-copy"/></span>
                  </div>
                  <div v-if="item.sub_plan !== '-'">{{item.sub_plan}}</div>
                  <div v-if="item.sub_gateway !== '-'">{{item.sub_gateway}}</div>
                </td>
              </template>

              <template #copy="{item}">
                <td>
                  <div v-if="item['card_update_link'] !== ''" class="text-nowrap">
                    Payment Update Link <span class="copy-text-btn"
                                      @click="copyToClipboard(String(item['card_update_link']))"><CIcon name="cil-copy"/></span></div>
                  <div v-if="item['setup_code'] !== '-'" class="text-nowrap">
                    Setup Code <span class="copy-text-btn"
                                     @click="copyToClipboard(String(item['setup_code']))"><CIcon name="cil-copy"/></span>
                  </div>
                  <div v-if="item['partition_key'] !== '-'" class="text-nowrap">
                    Login Info <span class="copy-text-btn"
                               @click="copyToClipboard(formatLoginInfoToCopy(item['partition_key'], item['admin_key']))"><CIcon name="cil-copy"/></span>
                  </div>
                </td>
              </template>

              <template #invoices="{item}">
                <td>
                  <router-link :to="{ path: '/invoices', query: { network_id: item.partition_key }}" target="_blank">Open</router-link>
                </td>
              </template>

              <template #sub_status="{item}">
                <td>
                  <span v-if="item.sub_status === '-'">{{item.sub_status}}</span>
                  <CBadge v-else :color="getColorByStatus(item.sub_status)">
                    {{item.sub_status}}<template v-if="item.sub_status === 'active' && item.sub_cancel_at">, payments stopped</template>
                  </CBadge>
                </td>
              </template>

              <template #actions="{item}">
                <td class="py-2">
                  <CButton
                      color="danger"
                      block
                      size="sm"
                      @click="startCancellationProcess('cancel', item.partition_key)"
                  >
                    Cancel
                  </CButton>
                  <CButton
                      color="danger"
                      block
                      size="sm"
                      @click="startCancellationProcess('cancel', item.partition_key, true)"
                  >
                    Instant Cancel
                  </CButton>
                </td>
              </template>
            </CDataTable>

          </CCardBody>
        </CCard>

      </CCol>
    </CRow>
    <CModal
        title="Choose a reason"
        color="info"
        :show.sync="isModalActive"
        @update:show="resetCancellationData()"
    >
      <CInputRadio
          v-for="(option, optionIndex) in cancellationReasons"
          :key="optionIndex"
          name="cancellation_reason"
          :label="option"
          :value="option"
          :checked="selectedReason === option"
          @update:checked="selectedReason = option"
      />
      <CInput
          style="margin-top: 5px;"
          v-model="otherReasonMessage"
          v-if="isSelectedOtherReason"
      />

      <template #footer-wrapper>
        <footer class="modal-footer">
          <slot name="footer">
            <button
                type="button"
                class="btn btn-info"
                @click="cancelSubscription()">
              OK
            </button>
          </slot>
        </footer>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios';
import {Auth} from "aws-amplify";
import dayjs from 'dayjs';
import SubscriptionService from "../../services/SubscriptionService";

export default {
  name: 'CancelSub',
  components: {},
  data() {
    return {
      query: '',
      confirmationModal: false,
      columns: [
        {key: 'partition_key', label: "Customer ID", _classes: ['text-nowrap']},
        {key: 'dates', label: 'Dates'},
        {key: 'email', label: 'Email'},
        {key: 'domains', label: 'Domains'},
        {key: 'subscription', label: 'Subscription', _classes: ['text-nowrap']},
        {key: 'invoices', label: 'Invoices'},
        {key: 'copy', label: 'Copy'},
        {key: 'sub_status', label: 'Status'},
        {
          key: 'actions',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ],
      networkItems: [],
      is_loading: false,

      // Cancellation functionality
      cancellationReasons: [
        "Canceled by admin",
        "Discontinuing the site",
        "Moving to another provider",
        "Switching to another technology",
        "Too expensive",
        "Other",
      ],
      selectedReason: 'Canceled by admin',
      otherReasonMessage: '',
      isModalActive: false,
      cancelSubscriptionData: null
    }
  },
  methods: {
    async send() {

      if(!this.query) {
        alert('Query can not be empty.');
        return;
      }

      this.is_loading = true;

      const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();

      axios
          .post(
              process.env.VUE_APP_MY_API_URL + '/search',
              {
                query: this.query
              },
              {
                headers: {
                  Authorization: jwtToken
                }
              }
          )
          .then(response => {

            // this.$toast.success(response.data.message)
            this.networkItems = response.data;

            this.is_loading = false;
          })
          .catch(error => {

            if (error.response) {

              this.$toast.error(error.response.data.message);

            } else {
              console.log('Error', error.message);
            }

            this.networkItems = [];
            this.is_loading = false;
          });
    },
    async changeSubscriptionStatus(newStatus, networkId, isInstant = false) {

      if(!confirm('Are you sure?')) return;

      const cancellationReason = this.isSelectedOtherReason && this.otherReasonMessage || this.selectedReason || 'Canceled by admin';

      try {
        const response = (newStatus === 'suspend')
            ? await SubscriptionService.suspend(networkId)
            : await SubscriptionService.cancel(networkId, cancellationReason, isInstant);

        console.log(response.data.message);
        // this.$toast.success(response.data.message);

        await this.send();

      } catch (error) {
        console.log('Error', error.message);
      }
    },
    async startCancellationProcess(newStatus, networkId, isInstant = false) {
      this.isModalActive = true;
      this.cancelSubscriptionData = {
        newStatus,
        networkId,
        isInstant
      };
    },
    async cancelSubscription() {

      if(!this.cancelSubscriptionData) return;

      if(!this.selectedReason) {
        alert('Please choose a reason.');
        return;
      }

      if(this.isSelectedOtherReason && !this.otherReasonMessage) {
        alert('Please specify a reason.');
        return;
      }

      const {newStatus, networkId, isInstant} = this.cancelSubscriptionData;
      try {
        await this.changeSubscriptionStatus(newStatus, networkId, isInstant);

        this.isModalActive = false;
        this.resetCancellationData();
      } catch (error) {
        console.log(error);
      }
    },
    resetCancellationData() {
      this.selectedReason = 'Canceled by admin';
      this.cancelSubscriptionData = null;
      this.otherReasonMessage = '';
    },
    getColorByStatus(status) {
      switch(status) {
        case 'active': return 'success';
        case 'suspended': return 'secondary';
        case 'pending_deposit': return 'warning';
        case 'cancelled': return 'danger';
        default: return 'primary';
      }
    },
    async copyToClipboard(value) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$toast.success('The value has been copied to clipboard.')
        return navigator.clipboard.writeText(value);
      }
      return Promise.reject('The Clipboard API is not available.');
    },
    formatLoginInfoToCopy(customerId, adminKey) {
      return `https://my.limitloginattempts.com/\nCustomer ID: ${customerId}\nAdmin Key: ${adminKey}`;
    }
  },
  computed: {
    normalizedNetworkItems() {
      return this.networkItems.map(el => {
        return {
          partition_key: el.partition_key,
          admin_key: el.admin_key,
          created_at: dayjs.unix(el.created_at).format('MMM DD YYYY'),
          last_paid_at: el.last_paid_at ? dayjs.unix(el.last_paid_at).format('MMM DD YYYY') : 'never',
          last_used_at: el.last_used_at ? dayjs.unix(el.last_used_at).format('MMM YYYY') : 'never',
          sub_started_at: el.sub_started_at ? dayjs.unix(el.sub_started_at).format('MMM DD YYYY') : '',
          email: el.email || '-',
          domains: el.domains || [],
          sub_id: el.sub_id || '-',
          sub_plan: (Object.keys(el.sub_plan_details).length) ? `${el.sub_plan_details.name} ${el.sub_plan_details.price} /${el.sub_plan_details.interval}` : '-',
          sub_gateway: el.sub_gateway || '-',
          sub_url: el.sub_url || '-',
          setup_code: el.setup_code || '-',
          sub_status: el.sub_status || '-',
          sub_cancel_at: el.sub_cancel_at || '',
          card_update_link: `${process.env.VUE_APP_CHECKOUT_APP_URL}/payment-profile?network_id=${el.partition_key}&admin_key=${el.admin_key}`
        }
      });
    },
    isSelectedOtherReason() {
      return this.selectedReason === 'Other';
    }
  }
}
</script>

<style scoped lang="scss">
.copy-text-btn {
  cursor: pointer;
}
.domain-not-enabled-label {
  font-size: 13px;
}
</style>
